import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useState } from "react";
import Layout from "../../../components/Layout";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { useNavigate } from "react-router-dom";
import AcceptButton from "../../../components/AcceptButton";
import AdminButton from "../../../components/AdminButton";
import CancelButton from "../../../components/CancelButton";
import EmptyData from "../../../components/EmptyData";
import FilterButton from "../../../components/FilterButton";
import TableFooter from "../../../components/TableFooter";
import TableHeader from "../../../components/TableHeader";
import TableSkeleton from "../../../components/TableSkeleton";
import { put } from "../../../utils/Api";
import { toast } from "react-toastify";
import { formatCurrency } from "../../../utils/Common";

type VoucherData = {
  id: number;
  name: string;
  discount_value: number;
  valid_date_start: string;
  valid_date_end: string;
  created_at: string;
  order_count: number;
  order_total: number;
};

type VoucherSearchParams = {
  name: any;
  start_date: any;
  end_date: any;
};

const Voucher = (formikValues: any) => {
  const navigate = useNavigate();
  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<VoucherData, VoucherSearchParams>(
    "/admin/voucher",
    {
      name: null,
      start_date: null,
      end_date: null,
    },
    1,
  );

  // if (error) return <div>Error: {error.message}</div>;

  const [filterVisible, setFilterVisible] = useState(false);
  // const [filterName, setFilterName] = useState("");
  const [filterDate, setFilterDate] = useState<any>(null);

  const rawRecords = data as any;
  const records = rawRecords?.data?.map((item: VoucherData) => {
    return {
      ...item,
    };
  });

  const search = searchParams as VoucherSearchParams;

  const formatFilterDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatFilterDate(filterDate);

  return (
    <Layout>
      <Dialog
        header="Filter"
        visible={filterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setFilterVisible(false)}
      >
        {/* <div className="mb-[10px]">
          <label htmlFor="code-id" className="mb-[5px] block">
            Name
          </label>
          <div>
            <InputText
              id="code"
              className="w-full"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
            />
          </div>
        </div> */}
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Valid Date
          </label>
          <Calendar
            className="w-full"
            name="delivery_date"
            dateFormat="dd M yy"
            selectionMode="range"
            value={filterDate}
            onChange={(e) => {
              if (Array.isArray(e.value) && e.value.length === 2) {
                const [startDate, endDate] = e.value;
                setFilterDate([startDate, endDate]);
              }
            }}
          />
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              // setFilterName("");
              setFilterDate(null);
              handleSearchParamsChange({
                // name: null,
                start_date: null,
                end_date: null,
              });
              setFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                // name: filterName ? filterName : null,
                start_date: formattedFilterDate?.startDate,
                end_date: formattedFilterDate?.endDate,
              });
              setFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <TableHeader>
        <FilterButton
          onClick={() => setFilterVisible(true)}
          className="mb-[5px] mr-[10px]"
        />
        <AdminButton
          label="Create"
          onClick={() => {
            navigate("/voucher-create");
          }}
        />
      </TableHeader>
      <div className="px-[17px] pt-[17px] card-cfg">
        {loading && <TableSkeleton />}
        {!loading && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Order Quantity</th>
                    <th>Total Sales</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {records?.length > 0 && (
                  <tbody>
                    {records.map((row: VoucherData) => {
                      return (
                        <tr key={`code-table-row-${row.id}`}>
                          <td data-label="Name">{row.name}</td>
                          <td data-label="Amount">
                            {formatCurrency(row.discount_value)}
                          </td>
                          <td data-label="Order Quantity">{row.order_count}</td>
                          <td data-label="Total Sales">
                            {formatCurrency(row.order_total)}
                          </td>
                          <td data-label="Start Date">
                            {row.valid_date_start}
                          </td>
                          <td data-label="End Date">{row.valid_date_end}</td>
                          <td data-label="Action">
                            <div className="flex flex-wrap gap-3 justify-end lg:justify-start">
                              <Button
                                className="p-0 text-sm shrink-0"
                                text
                                onClick={() => {
                                  navigate("/voucher-create", {
                                    state: { voucher_id: row.id },
                                  });
                                }}
                              >
                                <img
                                  src={require("../../../assets/images/icon-view.png")}
                                  alt="View icon"
                                  className="w-[24px]"
                                />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default Voucher;
