import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginSuccess, loginFailure } from "../../redux/slices/authSlice";
import { get, post } from "../../utils/Api";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import ValidationError from "../../components/ValidationError";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { setRoles } from "../../redux/slices/commonSlice";

const Login = () => {
  const { height } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path || "/";
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      const { email, password } = values;
      try {
        setLoading(true);
        const response = await post("/login", { email, password });
        setLoading(false);
        if (response?.success && response?.data?.token) {
          dispatch(loginSuccess(response.data));
          const userRolesResponse = await get(`/get_user_roles`);
          if (userRolesResponse?.success && userRolesResponse?.data) {
            dispatch(setRoles(userRolesResponse.data));
          }
          if (response.data.role_id === 8) {
            navigate("/kol-create", {
              replace: true,
              state: { kol_id: response.data.kol_id },
            });
          } else {
            navigate(redirectPath, { replace: true });
          }
        } else {
          toast.error("Something went wrong!");
        }
      } catch (error: any) {
        setLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
        dispatch(loginFailure(error.message));
      }
    },
  });

  return (
    <div
      className="flex bg-[#FFEDD7] md:bg-[#F1F1F1] md:bg-ipad-background-image md:bg-cover md:bg-center md:bg-no-repeat"
      style={{ height: `${height}px` }}
    >
      <div className="w-full max-w-md m-auto bg-white bg-opacity-90 p-[20px] rounded-[7px]">
        <header className="flex flex-col items-center">
          <img
            src={require("../../assets/images/logo-cfg-circle.png")}
            alt="CFG Logo"
            className="h-[131px] mx-auto mb-[30px]"
          />
        </header>
        <form onSubmit={formik.handleSubmit}>
          <div className="pb-[20px]">
            <InputText
              className={`w-full ${
                formik.touched.email && formik.errors.email ? "p-invalid" : ""
              }`}
              name="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.email && formik.errors.email
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.email}
            </ValidationError>
          </div>
          <div className="pb-[20px]">
            <Password
              className={`w-full ${
                formik.touched.password && formik.errors.password
                  ? "p-invalid"
                  : ""
              }`}
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // toggleMask
              feedback={false}
              inputStyle={{ width: "100%" }}
            />
            <ValidationError
              className={
                formik.touched.password && formik.errors.password
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.password}
            </ValidationError>
          </div>
          <div className="pb-[20px]">
            <Button
              label="Login"
              loading={loading}
              className="w-full bg-[#DA5E18] text-[#fff] text-[20px] border-0"
            />
          </div>
        </form>
        <footer className="flex justify-center items-center">
          <NavLink
            to="/forgot-password"
            className="text-[#C54700] hover:text-[#E5622A] text-[18px]"
          >
            Forgot Password?
          </NavLink>
        </footer>
      </div>
    </div>
  );
};

export default Login;
