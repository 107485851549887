import Layout from "../../../components/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { get, post, put } from "../../../utils/Api";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import AcceptButton from "../../../components/AcceptButton";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { InputNumber } from "primereact/inputnumber";
import { format } from "date-fns";
import ValidationError from "../../../components/ValidationError";
import TableFooter from "../../../components/TableFooter";
import { formatCurrency, formatDate, formatTime } from "../../../utils/Common";
import TableSkeleton from "../../../components/TableSkeleton";
import FullScreenDialog from "../../../components/FullScreenDialog";
import OrderDetails from "../../OrderDetails";
import CancelButton from "../../../components/CancelButton";
import { Dialog } from "primereact/dialog";
import TableHeader from "../../../components/TableHeader";
import FilterButton from "../../../components/FilterButton";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";

type OrderData = {
  id: number;
  total: number;
  status: number;
  created_at: string;
};

type OrderSearchParams = {
  start_date: string;
  end_date: string;
};

const PurchaseWithPurchaseCreate = (props: any) => {
  const navigate = useNavigate();
  const [pwp_id] = useState(useLocation()?.state?.pwp_id);
  const [pwpLoading, setPwpLoading] = useState(false);
  const [orderFilterVisible, setOrderFilterVisible] = useState(false);
  const [filterOrderDate, setFilterOrderDate] = useState<any>(null);
  const [validDate, setValidDate] = useState<any>(null);
  const [ordersData, setOrdersData] = useState([]);

  const [selectedProductId, setSelectedProductId] = useState<any>(null);
  const [selectedDesignatedProductId, setSelectedDesignatedProductId] =
    useState<any>(null);
  const [allProducts, setAllProducts] = useState<any>(null);
  const [allDesignatedProducts, setAllDesignatedProducts] = useState<any>(null);
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailParam, setShowDetailParam] = useState({});

  const thresholdOptions = [
    { name: "Order Price", value: "order_price" },
    { name: "Designated Product", value: "product_qty" },
  ];

  const productTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src={option.image_url}
          className={`mr-2`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  useEffect(() => {
    if (pwp_id) {
      getPwpDetail();
    }
    getAllProducts();
    getAllDesignatedProducts();
  }, []);

  const getPwpDetail = async () => {
    try {
      const response = await get(`/admin/exchange/${pwp_id}`);
      if (response?.success && response?.data) {
        setSelectedProductId(response?.data?.products[0]?.id || null);
        setSelectedDesignatedProductId(
          response?.data?.designated_product?.id || null,
        );

        formik.setValues({
          id: response?.data?.id || null,
          name: response?.data?.name || "",
          exchange_price: response?.data?.products[0]?.exchange_price || null,
          purchase_method: response?.data?.purchase_method || "",
          valid_date_start: response?.data?.valid_date_start
            ? format(new Date(response?.data?.valid_date_start), "yyyy-MM-dd")
            : "",
          valid_date_end: response?.data?.valid_date_end
            ? format(new Date(response?.data?.valid_date_end), "yyyy-MM-dd")
            : "",
          purchase_value: response?.data?.purchase_value || null,
          designated_product_id: response?.data?.designated_product?.id || null,
          product_id: response?.data?.products[0]?.id || null,
          // status: response?.data?.status || 0,
        });

        setValidDate([
          new Date(response?.data?.valid_date_start),
          new Date(response?.data?.valid_date_end),
        ]);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const getAllProducts = async () => {
    try {
      const response = await get("/admin/get_all_products?is_plan=0");
      if (response?.success && response?.data) {
        setAllProducts(response?.data);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const getAllDesignatedProducts = async () => {
    try {
      const response = await get("/admin/get_all_products?is_all=1");
      if (response?.success && response?.data) {
        setAllDesignatedProducts(response?.data);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      id: null,
      name: "",
      exchange_price: null,
      purchase_method: "order_price",
      valid_date_start: "",
      valid_date_end: "",
      purchase_value: null,
      designated_product_id: null,
      product_id: null,
      // status: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      valid_date_start: Yup.date().required("Start Date is required"),
      valid_date_end: Yup.date().required("End Date is required"),
      exchange_price: Yup.number().required("Discounted Price is required"),
      product_id: Yup.number().required("Product is required"),
      purchase_value: Yup.number().required("Value is required"),
    }),
    onSubmit: async (values) => {
      const {
        id,
        name,
        exchange_price,
        purchase_method,
        purchase_value,
        designated_product_id,
        product_id,
        valid_date_start,
        valid_date_end,
      } = values;
      let response;
      try {
        setPwpLoading(true);
        if (id) {
          let finalValues = {
            name,
            purchase_method,
            purchase_value,
            valid_date_start,
            valid_date_end,
            product_infos: [
              { exchange_price: exchange_price, product_id: product_id },
            ],
          } as Record<string, any>;

          if (designated_product_id && purchase_method === "product_qty") {
            finalValues = { ...finalValues, designated_product_id };
          }

          response = await put(`/admin/exchange/${id}`, finalValues);
        } else {
          const formData = new FormData();
          formData.append("name", name);
          formData.append("purchase_method", purchase_method);
          formData.append(
            "purchase_value",
            purchase_value as unknown as string,
          );
          if (designated_product_id && purchase_method === "product_qty") {
            formData.append(
              "designated_product_id",
              designated_product_id as unknown as string,
            );
          }
          formData.append("valid_date_start", valid_date_start);
          formData.append("valid_date_end", valid_date_end);

          formData.append(
            "product_infos[0][product_id]",
            product_id as unknown as string,
          );
          formData.append(
            "product_infos[0][exchange_price]",
            exchange_price as unknown as string,
          );

          console.log(formData);

          response = await post("/admin/exchange", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
        setPwpLoading(false);

        if (response?.success) {
          toast.success(response?.message);
          navigate("/pwp");
        }
      } catch (error: any) {
        setPwpLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const handleDateChange = (fieldName: any, selectedDate: any) => {
    const formattedDate = selectedDate
      ? format(selectedDate, "yyyy-MM-dd")
      : "";
    formik.setFieldValue(fieldName, formattedDate);
  };

  // if(code_id) {
  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<OrderData, OrderSearchParams>(
    `admin/exchange/${pwp_id}/orders`,
    {
      start_date: "",
      end_date: "",
    },
    1,
  );
  const rawRecords = data as any;
  const records = rawRecords.orders as OrderData[];
  // }

  const handleView = (id: any) => {
    setShowDetailParam({ order_id: id });
    setShowDetail(true);
  };

  const formatCalanderDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatCalanderDate(filterOrderDate);
  // const formattedValidDate = formatCalanderDate(filterOrderDate);

  return (
    <Layout>
      <FullScreenDialog
        visible={showDetail}
        onHide={() => setShowDetail(false)}
      >
        <OrderDetails params={showDetailParam} close={setShowDetail} />
      </FullScreenDialog>
      <Dialog
        header="Filter"
        position="bottom"
        visible={orderFilterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setOrderFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Order Date
          </label>
          <Calendar
            className="w-full"
            name="order_date"
            dateFormat="dd M yy"
            selectionMode="range"
            value={filterOrderDate}
            onChange={(e) => {
              if (Array.isArray(e.value) && e.value.length === 2) {
                const [startDate, endDate] = e.value;
                setFilterOrderDate([startDate, endDate]);
              }
            }}
          />
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterOrderDate(null);
              handleSearchParamsChange({
                start_date: "",
                end_date: "",
              });
              setOrderFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                start_date: formattedFilterDate?.startDate,
                end_date: formattedFilterDate?.endDate,
              });
              setOrderFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <button onClick={() => navigate(-1)} className="py-[8px] px-[12px]">
        <img
          src={require("../../../assets/images/icon-back.png")}
          alt="Back icon"
          className="w-[34px] h-[34px]"
        />
      </button>
      <div className="min-h-auto p-[17px] card-cfg">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-left items-center pb-[15px] text-[#DA5E18]">
            <label>Purchase with Purchase</label>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Name</label>
              <InputText
                className={`w-full ${
                  formik.touched.name && formik.errors.name ? "p-invalid" : ""
                }`}
                name="name"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.name && formik.errors.name ? "block" : "hidden"
                }
              >
                {formik.errors.name}
              </ValidationError>
            </div>
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Valid Date</label>
              <Calendar
                className={`w-full ${
                  (formik.touched.valid_date_start &&
                    formik.errors.valid_date_start) ||
                  (formik.touched.valid_date_end &&
                    formik.errors.valid_date_end)
                    ? "p-invalid"
                    : ""
                }`}
                name="valid_date"
                placeholder="Valid Date"
                dateFormat="dd M yy"
                selectionMode="range"
                value={validDate}
                onChange={(e) => {
                  if (Array.isArray(e.value) && e.value.length === 2) {
                    const [startDate, endDate] = e.value;
                    setValidDate([startDate, endDate]);
                    handleDateChange("valid_date_start", startDate);
                    handleDateChange("valid_date_end", endDate);
                  }
                }}
              />
              <ValidationError
                className={
                  formik.touched.valid_date_start &&
                  formik.errors.valid_date_start
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.valid_date_start}
              </ValidationError>
              <ValidationError
                className={
                  formik.touched.valid_date_end && formik.errors.valid_date_end
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.valid_date_end}
              </ValidationError>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Threshold</label>
              <div className="grid grid-cols-3 gap-4">
                <SelectButton
                  optionLabel="name"
                  value={formik.values.purchase_method}
                  onChange={(e) => {
                    formik.setFieldValue(`purchase_method`, e.value);
                  }}
                  width="100%"
                  unselectable={false}
                  options={thresholdOptions}
                />

                {formik.values.purchase_method === "product_qty" && (
                  <Dropdown
                    value={selectedDesignatedProductId}
                    onChange={(e) => {
                      setSelectedDesignatedProductId(e.value);
                      formik.setFieldValue(
                        `designated_product_id`,
                        e.value.toString(),
                      );
                      console.log(e.value);
                    }}
                    options={allDesignatedProducts}
                    optionLabel="name"
                    optionValue="id"
                    filter
                    placeholder="Select Product"
                    className={`w-auto md:w-20rem ${
                      formik.touched.designated_product_id &&
                      formik.errors.designated_product_id
                        ? "p-invalid"
                        : ""
                    }`}
                    itemTemplate={productTemplate}
                  />
                )}
                <InputNumber
                  className={`w-auto ${
                    formik.touched.purchase_value &&
                    formik.errors.purchase_value
                      ? "p-invalid"
                      : ""
                  }`}
                  name="purchase_value"
                  placeholder="Value"
                  minFractionDigits={2}
                  value={formik.values.purchase_value}
                  onChange={(e) =>
                    formik.setFieldValue("purchase_value", e.value)
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Applicable Product</label>
              <Dropdown
                value={selectedProductId}
                onChange={(e) => {
                  setSelectedProductId(e.value);
                  formik.setFieldValue(`product_id`, e.value.toString());
                  console.log(e.value);
                }}
                options={allProducts}
                optionLabel="name"
                optionValue="id"
                filter
                placeholder="Select Product"
                className={`w-auto md:w-20rem ${
                  formik.touched.product_id && formik.errors.product_id
                    ? "p-invalid"
                    : ""
                }`}
                itemTemplate={productTemplate}
              />
              <ValidationError
                className={
                  formik.touched.product_id && formik.errors.product_id
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.product_id}
              </ValidationError>
            </div>
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Discounted Price</label>
              <InputNumber
                className={`w-auto ${
                  formik.touched.exchange_price && formik.errors.exchange_price
                    ? "p-invalid"
                    : ""
                }`}
                name="exchange_price"
                placeholder="Discounted Price"
                minFractionDigits={2}
                value={formik.values.exchange_price}
                onChange={(e) =>
                  formik.setFieldValue("exchange_price", e.value)
                }
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.exchange_price && formik.errors.exchange_price
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.exchange_price}
              </ValidationError>
            </div>
          </div>

          {/* <div className="flex flex-col mb-4">
            <label className="ml-[5px] mb-[5px]">
              Status : {formik.values.status ? "Active" : "Inactive"}
            </label>
            <InputSwitch
              name="status"
              inputId="status"
              checked={formik.values.status ? true : false}
              onChange={(e) => {
                formik.setFieldValue(`status`, e.value ? 1 : 0);
              }}
              onBlur={formik.handleBlur}
            />
          </div> */}
          <AcceptButton
            label={pwp_id ? "Update" : "Save"}
            className="mt-[20px] ml-auto block"
            loading={pwpLoading}
          />
        </form>
        {pwp_id && (
          <div className="pt-[17px]">
            <div className="flex flex-wrap">
              <div className="flex w-full justify-between items-center pb-[15px] text-[#DA5E18]">
                <div className="flex flex-wrap gap-3 items-center">
                  <label>Orders History</label>
                  <div
                    className={
                      "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
                    }
                  >
                    Orders
                    <span className="text-[24px] ml-[8px]">
                      {rawRecords?.orders_total_qty ?? 0}
                    </span>
                  </div>
                  <div
                    className={
                      "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
                    }
                  >
                    Sales
                    <span className="text-[24px] ml-[8px]">
                      {rawRecords?.orders_total_sales
                        ? formatCurrency(rawRecords.orders_total_sales)
                        : 0}
                    </span>
                  </div>
                </div>
                <FilterButton onClick={() => setOrderFilterVisible(true)} />
              </div>
            </div>
            {loading && pwp_id && <TableSkeleton />}
            {!loading && pwp_id && records && (
              <div className="overflow-x-auto">
                <table className="table-cfg">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((row: OrderData) => {
                      return (
                        <tr key={`table-row-${row?.id}`}>
                          <td data-label="Order ID">
                            <label
                              className="text-[#E5622A] cursor-pointer underline"
                              onClick={() => handleView(row?.id)}
                            >
                              #{row?.id}
                            </label>
                          </td>
                          <td data-label="Total">
                            {formatCurrency(row?.total)}
                          </td>
                          <td data-label="Status" className="text-center">
                            <div
                              className={`${
                                row?.status === 1
                                  ? "bg-green-300"
                                  : row?.status === 0
                                  ? "bg-gray-300"
                                  : "bg-red-300 "
                              } px-[8px] py-[3px] rounded-[30px] inline text-xs w-fit lg:w-full`}
                            >
                              {row?.status === 1
                                ? "Paid"
                                : row?.status === 0
                                ? "Unpaid"
                                : row?.status === 4
                                ? "Refunded"
                                : "Failed"}
                            </div>
                          </td>
                          <td data-label="Date">
                            <div className="whitespace-nowrap">
                              {formatDate(row?.created_at)}
                              <span className="text-xs pl-[5px]">
                                {formatTime(row?.created_at)}
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            {/* <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            /> */}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PurchaseWithPurchaseCreate;
function useApiData(arg0: string) {
  throw new Error("Function not implemented.");
}
