import Layout from "../../../components/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { get, post, put } from "../../../utils/Api";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import AcceptButton from "../../../components/AcceptButton";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { InputNumber } from "primereact/inputnumber";
import { format } from "date-fns";
import ValidationError from "../../../components/ValidationError";
import TableFooter from "../../../components/TableFooter";
import {
  formatCurrency,
  formatDate,
  formatTime,
  isRole,
} from "../../../utils/Common";
import TableSkeleton from "../../../components/TableSkeleton";
import FullScreenDialog from "../../../components/FullScreenDialog";
import OrderDetails from "../../OrderDetails";
import CancelButton from "../../../components/CancelButton";
import { Dialog } from "primereact/dialog";
import TableHeader from "../../../components/TableHeader";
import FilterButton from "../../../components/FilterButton";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";

type LinkData = {
  id: number;
  kol_id: number;
  link: string;
  name: string;
  order_count: number;
  order_total: number;
  product: object;
  product_id: number;
  status: number;
  valid_date_end: string;
  valid_date_start: string;
};

type OrderData = {
  id: number;
  total: number;
  status: number;
  created_at: string;
};

type OrderSearchParams = {
  start_date: string;
  end_date: string;
  product_id: string;
};

const KolCreate = (props: any) => {
  const navigate = useNavigate();
  const [kol_id] = useState(useLocation()?.state?.kol_id);
  const [kolLoading, setKolLoading] = useState(false);

  const [orderFilterVisible, setOrderFilterVisible] = useState(false);
  const [filterOrderDate, setFilterOrderDate] = useState<any>(null);
  const [filterProductId, setFilterProductId] = useState("");

  const [linksData, setLinksData] = useState([]);
  const [showLinkDetail, setShowLinkDetail] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);

  const [allProducts, setAllProducts] = useState<any>(null);

  const [allUsers, setAllUsers] = useState<any>(null);

  const [showOrderDtail, setshowOrderDtail] = useState(false);
  const [showOrderDtailParam, setshowOrderDtailParam] = useState({});

  const productTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src={option.image_url}
          className={`mr-2`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  useEffect(() => {
    getUserList();
    if (kol_id) {
      getKolDetail();
      getAllProducts();
    }
  }, []);

  const getKolDetail = async () => {
    try {
      const response = await get(`/admin/kol/${kol_id}`);
      if (response?.success && response?.data) {
        formik.setValues({
          id: response?.data?.id || null,
          name: response?.data?.name || "",
          address: response?.data?.address || "",
          remark: response?.data?.remark || "",
          commission_rate: response?.data?.commission_rate || 0,
          user_id: response?.data?.user_id || null,
        });

        setLinksData(response?.data?.kolLinks);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const getUserList = async () => {
    try {
      const response = await get(
        `/admin/get_users_list?role_id=8&is_no_pagination=1&is_has_kol=${
          kol_id ? 1 : 0
        }`,
      );
      if (response?.success && response?.data) {
        console.log(response?.data);
        setAllUsers(response?.data);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      id: null,
      name: "",
      remark: "",
      address: "",
      commission_rate: 0,
      user_id: null,
    },
    validationSchema: Yup.object({
      user_id: Yup.number().required("User is required"),
      commission_rate: Yup.number()
        .required("Commision Rate is required")
        .min(0.01, "Minimum 0.01")
        .max(100, "Maximum 100"),
    }),
    onSubmit: async (values) => {
      const { id, name, address, remark, commission_rate, user_id } = values;
      let response;
      try {
        setKolLoading(true);
        if (id) {
          response = await put(`/admin/kol/${id}`, values);
        } else {
          const formData = new FormData();
          if (name) {
            formData.append("name", name);
          }
          formData.append(
            "commission_rate",
            commission_rate as unknown as string,
          );
          if (address) {
            formData.append("address", address);
          }
          if (remark) {
            formData.append("remark", remark);
          }
          formData.append("user_id", user_id as unknown as string);

          console.log(formData);

          response = await post("/admin/kol", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
        setKolLoading(false);

        if (response?.success) {
          toast.success(response?.message);
          navigate("/kol");
        }
      } catch (error: any) {
        setKolLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const handleDateChange = (fieldName: any, selectedDate: any) => {
    const formattedDate = selectedDate
      ? format(selectedDate, "yyyy-MM-dd")
      : "";
    linkFormik.setFieldValue(fieldName, formattedDate);
  };

  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<OrderData, OrderSearchParams>(
    `admin/kol/${kol_id}/orders`,
    {
      start_date: "",
      end_date: "",
      product_id: "",
    },
    1,
  );
  const rawRecords = data as any;
  const records = rawRecords.orders as OrderData[];

  const handleView = (id: any) => {
    setshowOrderDtailParam({ order_id: id });
    setshowOrderDtail(true);
  };

  const getAllProducts = async () => {
    try {
      const response = await get("/admin/get_all_for_kol_products");
      if (response?.success && response?.data) {
        const products = response?.data.filter(
          (item: any) => item.category_id !== 4,
        );
        setAllProducts(products);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const copyToClipboard = (text: string): void => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast("Link copied");
        console.log("Text copied to clipboard:", text);
      })
      .catch((err) => {
        toast.error("Link copy failed");
        console.error("Failed to copy text:", err);
      });
  };

  const linkFormik = useFormik({
    initialValues: {
      id: 0,
      kol_id: kol_id,
      name: "",
      valid_date_start: "",
      valid_date_end: "",
      product_id: 0,
      status: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      valid_date_start: Yup.date()
        .required("Start Date is required")
        .max(Yup.ref("valid_date_end"), "Start Date cannot be after End Date"),
      valid_date_end: Yup.date()
        .required("End Date is required")
        .min(
          Yup.ref("valid_date_start"),
          "End Date cannot be before Start Date",
        ),
      product_id: Yup.number().min(0.1, "Product is required"),
    }),
    onSubmit: async (values) => {
      const {
        id,
        name,
        kol_id,
        valid_date_start,
        valid_date_end,
        product_id,
        status,
      } = values;
      let response;
      try {
        setLinkLoading(true);
        if (id) {
          response = await put(`/admin/kolLink/${id}`, {
            name,
            product_id,
            valid_date_start,
            valid_date_end,
            status: status ? 1 : 0,
          });
        } else {
          const formData = new FormData();
          formData.append("name", name);
          formData.append("kol_id", kol_id as unknown as string);
          formData.append("product_id", product_id as unknown as string);
          formData.append("valid_date_start", valid_date_start);
          formData.append("valid_date_end", valid_date_end);
          formData.append("status", status ? "1" : "0");

          console.log(formData);

          response = await post("/admin/kolLink", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
        setLinkLoading(false);

        if (response?.success) {
          toast.success(response?.message);
          setShowLinkDetail(false);
          formik.resetForm();
          getKolDetail();
        }
      } catch (error: any) {
        setLinkLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const handleLinkUpdate = (row: any) => {
    linkFormik.setValues({
      id: row.id,
      name: row.name,
      valid_date_start: row.valid_date_start,
      valid_date_end: row.valid_date_end,
      status: row.status === 1,
      kol_id: row.kol_id || kol_id,
      product_id: row.product_id,
    });
    setShowLinkDetail(true);
  };

  const formatCalanderDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatCalanderDate(filterOrderDate);
  // const formattedValidDate = formatCalanderDate(filterOrderDate);

  return (
    <Layout>
      <FullScreenDialog
        visible={showOrderDtail}
        onHide={() => setshowOrderDtail(false)}
      >
        <OrderDetails params={showOrderDtailParam} close={setshowOrderDtail} />
      </FullScreenDialog>
      <Dialog
        header="Filter"
        position="bottom"
        visible={orderFilterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setOrderFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label
            htmlFor="product"
            className="text-[#292929] capitalize block mb-2"
          >
            Product
          </label>
          <Dropdown
            value={filterProductId}
            onChange={(e) => {
              setFilterProductId(e.value);
              console.log(e.value);
            }}
            options={allProducts}
            optionLabel="name"
            optionValue="id"
            placeholder="Select a Product"
            itemTemplate={productTemplate}
            className="w-full md:w-20rem"
          />
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Order Date
          </label>
          <Calendar
            className="w-full"
            name="order_date"
            dateFormat="dd M yy"
            selectionMode="range"
            value={filterOrderDate}
            onChange={(e) => {
              if (Array.isArray(e.value) && e.value.length === 2) {
                const [startDate, endDate] = e.value;
                setFilterOrderDate([startDate, endDate]);
              }
            }}
          />
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterOrderDate(null);
              setFilterProductId("");
              handleSearchParamsChange({
                start_date: "",
                end_date: "",
                product_id: "",
              });
              setOrderFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                start_date: formattedFilterDate?.startDate,
                end_date: formattedFilterDate?.endDate,
                product_id: filterProductId,
              });
              setOrderFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        header="Link"
        visible={showLinkDetail}
        className="w-[90vw] md:w-auto border-0"
        onHide={() => {
          setShowLinkDetail(false);
          linkFormik.resetForm();
        }}
      >
        <form onSubmit={linkFormik.handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 rounded-[7px]">
            <div className="md:col-span-2">
              <label
                htmlFor="name"
                className="text-[#292929] capitalize block mb-2"
              >
                Link Name
              </label>
              <InputText
                className={`w-full ${
                  linkFormik.touched.name && linkFormik.errors.name
                    ? "p-invalid"
                    : ""
                }`}
                name="name"
                id="name"
                placeholder="Link Name"
                value={linkFormik.values.name}
                onChange={linkFormik.handleChange}
                onBlur={linkFormik.handleBlur}
              />
              <ValidationError
                className={
                  linkFormik.touched.name && linkFormik.errors.name
                    ? "block"
                    : "hidden"
                }
              >
                {linkFormik.errors.name}
              </ValidationError>
            </div>
            <div className="md:col-span-2">
              <label
                htmlFor="product"
                className="text-[#292929] capitalize block mb-2"
              >
                Product
              </label>
              <Dropdown
                value={linkFormik.values.product_id}
                onChange={(e) => {
                  linkFormik.setFieldValue(`product_id`, e.value);
                  // console.log(e);
                }}
                options={allProducts}
                optionLabel="name"
                optionValue="id"
                placeholder="Select a Product"
                itemTemplate={productTemplate}
                className={`w-full md:w-20rem ${
                  linkFormik.touched.product_id && linkFormik.errors.product_id
                    ? "p-invalid"
                    : ""
                }`}
              />
              <ValidationError
                className={
                  linkFormik.touched.product_id && linkFormik.errors.product_id
                    ? "block"
                    : "hidden"
                }
              >
                {linkFormik.errors.product_id}
              </ValidationError>
            </div>
            <div className="md:col-span-1">
              <label
                htmlFor="valid_date_start"
                className="text-[#292929] capitalize block mb-2"
              >
                Start Date
              </label>
              <Calendar
                className={`w-full ${
                  linkFormik.touched.valid_date_start &&
                  linkFormik.errors.valid_date_start
                    ? "p-invalid"
                    : ""
                }`}
                name="valid_date_start"
                inputId="valid_date_start"
                placeholder="Start Date"
                value={
                  linkFormik.values.valid_date_start
                    ? new Date(linkFormik.values.valid_date_start)
                    : null
                }
                onChange={(e) => handleDateChange("valid_date_start", e.value)}
                dateFormat="dd M yy"
              />
              <ValidationError
                className={
                  linkFormik.touched.valid_date_start &&
                  linkFormik.errors.valid_date_start
                    ? "block"
                    : "hidden"
                }
              >
                {linkFormik.errors.valid_date_start}
              </ValidationError>
            </div>
            <div className="md:col-span-1">
              <label
                htmlFor="valid_date_end"
                className="text-[#292929] capitalize block mb-2"
              >
                End Date
              </label>
              <Calendar
                className={`w-full ${
                  linkFormik.touched.valid_date_end &&
                  linkFormik.errors.valid_date_end
                    ? "p-invalid"
                    : ""
                }`}
                name="valid_date_end"
                inputId="valid_date_end"
                placeholder="End Date"
                value={
                  linkFormik.values.valid_date_end
                    ? new Date(linkFormik.values.valid_date_end)
                    : null
                }
                onChange={(e) => handleDateChange("valid_date_end", e.value)}
                dateFormat="dd M yy"
              />
              <ValidationError
                className={
                  linkFormik.touched.valid_date_end &&
                  linkFormik.errors.valid_date_end
                    ? "block"
                    : "hidden"
                }
              >
                {linkFormik.errors.valid_date_end}
              </ValidationError>
            </div>
            <div className="md:col-span-1 flex flex-col">
              <label
                htmlFor="status"
                className="text-[#292929] capitalize block mb-2"
              >
                Status
              </label>
              <InputSwitch
                name="status"
                inputId="status"
                checked={linkFormik.values.status}
                onChange={linkFormik.handleChange}
                onBlur={linkFormik.handleBlur}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <AcceptButton
              label={linkFormik.values.id > 0 ? "Update" : "Save"}
              loading={linkLoading}
              className="mt-[20px]"
            />
          </div>
        </form>
      </Dialog>
      {!isRole(["KOL"]) && (
        <button onClick={() => navigate(-1)} className="py-[8px] px-[12px]">
          <img
            src={require("../../../assets/images/icon-back.png")}
            alt="Back icon"
            className="w-[34px] h-[34px]"
          />
        </button>
      )}
      <div className="min-h-auto p-[17px] card-cfg">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-left items-center pb-[15px] text-[#DA5E18]">
            <label>KOL</label>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Name</label>
              <InputText
                className={`w-full ${
                  formik.touched.name && formik.errors.name ? "p-invalid" : ""
                }`}
                name="name"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={true}
              />
              <ValidationError
                className={
                  formik.touched.name && formik.errors.name ? "block" : "hidden"
                }
              >
                {formik.errors.name}
              </ValidationError>
            </div>
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Address</label>
              <InputText
                className={`w-full ${
                  formik.touched.address && formik.errors.address
                    ? "p-invalid"
                    : ""
                }`}
                name="address"
                placeholder="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isRole(["KOL"])}
              />
              <ValidationError
                className={
                  formik.touched.address && formik.errors.address
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.address}
              </ValidationError>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Commision Rate</label>
              <InputNumber
                className={`w-full ${
                  formik.touched.commission_rate &&
                  formik.errors.commission_rate
                    ? "p-invalid"
                    : ""
                }`}
                inputId="commission_rate"
                placeholder="Enter commission rate %"
                value={formik.values.commission_rate}
                onValueChange={(e) =>
                  formik.setFieldValue("commission_rate", e.value)
                }
                // onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                useGrouping={false}
                minFractionDigits={2}
                suffix=" %"
                disabled={isRole(["KOL"])}
              />
              <ValidationError
                className={
                  formik.touched.commission_rate &&
                  formik.errors.commission_rate
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.commission_rate}
              </ValidationError>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="product"
                className="text-[#292929] capitalize block mb-2"
              >
                User
              </label>
              <Dropdown
                value={formik.values.user_id}
                onChange={(e) => {
                  const selectedId = e.value;
                  const selectedUser = allUsers.find(
                    (user: any) => user.id === selectedId,
                  );
                  formik.setFieldValue(`user_id`, selectedId);
                  formik.setFieldValue(`name`, selectedUser.full_name);
                }}
                options={allUsers}
                optionLabel="full_name"
                optionValue="id"
                placeholder="Select a User"
                className={`w-full md:w-20rem ${
                  formik.touched.user_id && formik.errors.user_id
                    ? "p-invalid"
                    : ""
                }`}
                disabled={isRole(["KOL"])}
              />
              <ValidationError
                className={
                  formik.touched.user_id && formik.errors.user_id
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.user_id}
              </ValidationError>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="md:col-span-2 flex flex-col">
              <label
                htmlFor="Remarks"
                className="text-[#292929] capitalize block mb-2"
              >
                Remarks
              </label>
              <InputTextarea
                className={`w-full ${
                  formik.touched.remark && formik.errors.remark
                    ? "p-invalid"
                    : ""
                }`}
                name="remark"
                placeholder="Remarks"
                value={formik.values.remark}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isRole(["KOL"])}
              />
            </div>
          </div>

          {!isRole(["KOL"]) && (
            <AcceptButton
              label={formik.values.id ? "Update" : "Save"}
              className="mt-[20px] ml-auto block"
              loading={kolLoading}
            />
          )}
        </form>
        {kol_id && (
          <div className="pt-[17px]">
            <div className="flex flex-wrap">
              <div className="flex w-full justify-between items-center pb-[15px] text-[#DA5E18]">
                <div className="flex flex-wrap gap-3 items-center">
                  <label>Affiliate Links</label>
                </div>
                {!isRole(["KOL"]) && (
                  <AcceptButton
                    label="Create Link"
                    className="mt-[20px] ml-auto block"
                    onClick={() => {
                      linkFormik.resetForm();
                      setShowLinkDetail(true);
                    }}
                  />
                )}
              </div>
            </div>
            {loading && kol_id && <TableSkeleton />}
            {!loading && kol_id && linksData && (
              <div className="overflow-x-auto">
                <table className="table-cfg">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Link</th>
                      <th>Order Qty</th>
                      <th>Total Sales</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {linksData.map((row: LinkData) => {
                      return (
                        <tr key={`table-row-${row?.id}`}>
                          <td data-label="Name">
                            <label
                              className={`text-[#E5622A] ${
                                isRole(["KOL"])
                                  ? ""
                                  : "cursor-pointer underline"
                              }`}
                              onClick={() => {
                                if (!isRole(["KOL"])) handleLinkUpdate(row);
                              }}
                            >
                              {row?.name}
                            </label>
                            <p className="text-xs">
                              {formatDate(row?.valid_date_start)} -{" "}
                              {formatDate(row?.valid_date_end)}
                            </p>
                          </td>
                          <td data-label="Link">
                            <div className="flex justify-between">
                              <span>{row?.link}</span>
                              <span
                                className="pi pi-copy cursor-pointer"
                                onClick={() => copyToClipboard(row?.link)}
                              ></span>
                            </div>
                          </td>
                          <td data-label="Order Qty">{row?.order_count}</td>
                          <td data-label="Total Sales">
                            {formatCurrency(row?.order_total)}
                          </td>
                          <td data-label="Status" className="text-center">
                            <div
                              className={`${
                                row.status === 1
                                  ? "bg-green-300"
                                  : "bg-gray-300"
                              } px-[8px] py-[3px] rounded-[30px] inline text-xs whitespace-nowrap w-fit lg:w-full`}
                            >
                              {row.status === 1 ? "Active" : "Inactive"}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            {/* <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            /> */}
          </div>
        )}
        {kol_id && (
          <div className="pt-[17px]">
            <div className="flex flex-wrap">
              <div className="flex w-full justify-between items-center pb-[15px] text-[#DA5E18]">
                <div className="flex flex-wrap gap-3 items-center">
                  <label>Orders History</label>
                  <div
                    className={
                      "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
                    }
                  >
                    Orders
                    <span className="text-[24px] ml-[8px]">
                      {rawRecords?.orders_total_qty ?? 0}
                    </span>
                  </div>
                  <div
                    className={
                      "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
                    }
                  >
                    Sales
                    <span className="text-[24px] ml-[8px]">
                      {rawRecords?.orders_total_sales
                        ? formatCurrency(rawRecords.orders_total_sales)
                        : 0}
                    </span>
                  </div>
                  <div
                    className={
                      "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
                    }
                  >
                    Total Commission
                    <span className="text-[24px] ml-[8px]">
                      {rawRecords?.orders_total_sales
                        ? formatCurrency(rawRecords.total_commission)
                        : 0}
                    </span>
                  </div>
                </div>
                <FilterButton onClick={() => setOrderFilterVisible(true)} />
              </div>
            </div>
            {loading && kol_id && <TableSkeleton />}
            {!loading && kol_id && records && (
              <div className="overflow-x-auto">
                <table className="table-cfg">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((row: OrderData) => {
                      return (
                        <tr key={`table-row-${row?.id}`}>
                          <td data-label="Order ID">
                            <label
                              className="text-[#E5622A] cursor-pointer underline"
                              onClick={() => handleView(row?.id)}
                            >
                              #{row?.id}
                            </label>
                          </td>
                          <td data-label="Total">
                            {formatCurrency(row?.total)}
                          </td>
                          <td data-label="Status" className="text-center">
                            <div
                              className={`${
                                row?.status === 1
                                  ? "bg-green-300"
                                  : row?.status === 0
                                  ? "bg-gray-300"
                                  : "bg-red-300 "
                              } px-[8px] py-[3px] rounded-[30px] inline text-xs w-fit lg:w-full`}
                            >
                              {row?.status === 1
                                ? "Paid"
                                : row?.status === 0
                                ? "Unpaid"
                                : row?.status === 4
                                ? "Refunded"
                                : "Failed"}
                            </div>
                          </td>
                          <td data-label="Date">
                            <div className="whitespace-nowrap">
                              {formatDate(row?.created_at)}
                              <span className="text-xs pl-[5px]">
                                {formatTime(row?.created_at)}
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            {/* <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            /> */}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default KolCreate;
function useApiData(arg0: string) {
  throw new Error("Function not implemented.");
}
