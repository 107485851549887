export interface User {
  agent_code: string | null;
  country_code: string | null;
  created_at: Date;
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  image_url: string | null;
  last_name: string;
  mobile: number | null;
  role_id: number;
  status: number;
  token: string;
  updated_at: Date;
  kol_id: number;
}

export interface AuthState {
  user: User | null;
  error: string | null;
}

export enum HeaderType {
  default = "DEFAULT",
  back = "BACK",
}

export interface SubCategory {
  category_id: number;
  id: number;
  name: string;
  status: number;
}
export interface Inventory {
  category: {
    id: number;
    name: string;
    status: number;
  };
  category_id: number;
  cost_price: number;
  created_at: string;
  free_gift: number;
  id: number;
  image_url: string | null;
  low_stocks: boolean;
  name: string;
  price: number;
  quantity: number;
  sku: string;
  status: number;
  sub_category: SubCategory;
  sub_category_id: number;
  subscription: number;
  updated_at: string;
  delivery_option_id: number;
  // product_movement: object[];
  deliveryDate: string;
  deliveryOption: number;
  purchaseOption: number;
  subscriptionOption: number;
  subscriptionQuantity: string;
  otpQuantity: string;
}

export interface FreeGift {
  id: number;
  name: string;
  sku: string;
  image_url: string;
  available_stocks: number;
  quantity?: number;
}

export interface InventoryGroup {
  products: Inventory[];
  sub_category: SubCategory;
}

export interface OrderSettings {
  created_at: string;
  delivery_date_days_offset: number;
  delivery_fee: number;
  gst: number;
  id: number;
  min_spend_free_delivery: number;
  min_subsription_order: number;
  updated_at: string;
}

export interface PurchaseOption {
  created_at: string;
  id: number;
  name: string;
  subscription_month: number;
  updated_at: string;
}

export interface SubscriptionOption {
  created_at: string;
  id: number;
  name: string;
  status: number;
  subscription_month: number;
  updated_at: string;
}

export interface DeliveryOption {
  created_at: string;
  id: number;
  name: string;
  status: number;
  updated_at: string;
}

export interface Customer {
  is_blacklist: number;
  country_code: string;
  created_at: string;
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  last_name: string;
  mobile: string;
  address: {
    postal_code: string;
    street: string;
    unit_no: string;
  };
  status: number;
  updated_at: string;
}

export interface InventoryCategory {
  created_at: string;
  id: number;
  name: string;
  recognised_percentage: string;
  before_commission_percentage: string;
  after_commission_percentage: string;
  fixed_commission_percentage: string;
  status: number;
  sub_category: Array<{
    category_id: number;
    created_at: string;
    id: number;
    name: string;
    status: number;
    updated_at: string;
  }>;
  updated_at: string;
}

export interface Role {
  id: number;
  name: string;
}
