import React, { useEffect, useState } from "react";
import { HeaderType } from "../../interfaces";
import Layout from "../../components/Layout";
import { Button } from "primereact/button";
import { useAppSelector } from "../../hooks/useStore";
import PurchaseOptionsDialog from "../../components/PurchaseOptionsDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AcceptButton from "../../components/AcceptButton";
import { Dialog } from "primereact/dialog";
import GridView from "../NewOrder/GridView";
import FreeGiftGrid from "../NewOrder/FreeGiftGrid";
import { Inventory, InventoryGroup } from "../../interfaces";
import { get } from "../../utils/Api";
import { Checkbox } from "primereact/checkbox";
import { store } from "../../redux/store";
import { formatCurrency } from "../../utils/Common";
import { useDispatch } from "react-redux";
import { setCart } from "../../redux/slices/orderSlice";
import { RadioButton } from "primereact/radiobutton";

const PlanAddons = () => {
  const dispatch = useDispatch();
  const { isEdit, cartIndex } = useLocation().state;
  const [plan, setPlan] = useState<any>(useLocation()?.state?.plan);
  const navigate = useNavigate();
  const { purchaseOptions, orderInfo, cart } = useAppSelector(
    (state) => state.order,
  );
  const plan_ids = cart.map((item: any) =>
    item?.cartItems?.map((cartItem: any) => cartItem?.id),
  );
  const samePlan =
    plan_ids?.length > 0 ? plan_ids[0].includes(plan?.id) : false;
  const [checkedSelfCollect, setCheckedSelfCollect] = useState(
    orderInfo?.subscriptionInfo?.selfCollect ?? false,
  );
  const [selectedPlan, setSelectedPlan] = useState<any>();
  const [purchaseOptionVisible, setPurchaseOptionVisible] = useState(false);
  const [selectedPurchaseOption, setSelectedPurchaseOption] = useState(
    orderInfo?.purchaseOption?.length > 0 &&
      orderInfo?.purchaseOption[0]?.id === 2
      ? "Subscription"
      : orderInfo?.purchaseOption?.length > 0 &&
        orderInfo?.purchaseOption[0]?.id === 1
      ? "One Time Purchase"
      : "Plan",
  );
  const [selectBeansVisible, setSelectBeansVisible] = useState(false);
  const [selectGiftsVisible, setSelectGiftsVisible] = useState(false);
  const [beans, setBeans] = useState<any[]>([]);
  const [focType, setFocType] = useState<string>("");
  const [dialogType, setDialogType] = useState<string>("");
  const [selectedBeans, setSelectedBeans] = useState<any[]>([]);
  const [freeGifts, setFreeGiftss] = useState<any[]>([]);
  const [selectedFreeGifts, setSelectedFreeGifts] = useState<any[]>([]);
  const [freeGiftsQty, setFreeGiftsQty] = useState<any>();

  const [pocketCoffee, setPocketCoffee] = useState<any>();
  const [pocketCoffees, setPocketCoffees] = useState<any>();

  useEffect(() => {
    console.log(plan);

    setFreeGiftss(plan.free_gift_list);
    setFreeGiftsQty(plan.free_gift_quantity);
    // console.log("beans", beans);
    // console.log("selectedBeans", selectedBeans);
    // console.log(plan);
  }, [selectedBeans]);
  useEffect(() => {
    // if (pocketCoffee) {
    //   setSelectedBeans([pocketCoffee]); // Update selectedBeans with pocketCoffee data
    // }
  }, [pocketCoffee]);
  useEffect(() => {
    const getPocketCoffeeProducts = async () => {
      try {
        const response = await get(`/get_pocket_coffee_products`);
        if (response.success && response.data) {
          setPocketCoffees(response.data);
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    };
    getPocketCoffeeProducts();
    // const getPocketCoffeeProduct = async () => {
    //   try {
    //     const response = await get(`/get_pocket_coffee_product`);
    //     if (response.success && response.data) {
    //       setPocketCoffee(response.data);
    //     }
    //   } catch (error: any) {
    //     if (
    //       error?.response?.data?.message &&
    //       typeof error?.response?.data?.message === "string"
    //     ) {
    //       toast.error(error.response.data.message);
    //     }
    //   }
    // };
    // getPocketCoffeeProduct();
    if (isEdit) {
      const products = plan?.bundle_items?.filter((bundle_item: any) =>
        ["product", "add_on"].includes(bundle_item?.type),
      );
      if (products) {
        setSelectedBeans(products);
      }
      const focs = plan?.bundle_items?.filter(
        (bundle_item: any) =>
          !["product", "add_on"].includes(bundle_item?.type),
      );
      if (focs) {
        setSelectedFreeGifts(focs);
        setFocType(focs[0]?.type);
      }
      setSelectedPlan(plan);
    }
  }, [plan, isEdit]);
  const headerButton = (
    <div className="w-full h-[70px] bg-[#DA5E18] text-white flex justify-between px-5">
      <Button
        link
        className="flex flex-row items-center flex-nowrap h-full"
        onClick={() => setPurchaseOptionVisible(true)}
      >
        <span className="text-[20px] text-white">{selectedPurchaseOption}</span>
        <img
          src={require("../../assets/images/icon-arrow-down-white.png")}
          alt="Down icon"
          className="w-[24px] h-[24px] ml-[5px]"
        />
      </Button>
    </div>
  );

  const validateItems = (cartArray: any) => {
    const errors: string[] = [];
    const addon_id = selectedPlan?.addon_id ?? null;
    const foc_validation = selectedPlan?.free_gift_list?.filter(
      (item_detail: any) => item_detail?.available_stocks > 0,
    );
    if (selectedPlan?.type === "product") {
      console.log("selectedPlan?.type === 'product'");
      const base_plan_validation = selectedPlan?.bundle?.items_detail?.filter(
        (item_detail: any) => item_detail?.quantity > 0,
      );
      console.log("base_plan_validation", base_plan_validation);
      console.log("cartArray", cartArray);
      cartArray?.map(
        ({ cartItems, orderInfo }: any) =>
          cartItems?.map((cartItem: any) => {
            if (
              cartItem?.id === selectedPlan?.id &&
              cartItem?.type === selectedPlan?.type
            ) {
              if (cartItem?.bundle_items?.length > 0) {
                if (plan?.pocket_coffee_boxes != null) {
                  const totalQuantity = cartItem?.bundle_items.reduce(
                    (acc: any, bean: { quantity: any; type: any }) =>
                      bean?.type !== focType
                        ? acc + (bean?.quantity || 0)
                        : acc,
                    0,
                  );
                  if (totalQuantity !== cartItem?.bundle?.total_beans) {
                    if (
                      !errors.includes(
                        `Please add exactly ${cartItem?.bundle?.total_beans}x pocket coffee for selected plan.`,
                      )
                    ) {
                      errors.push(
                        `Please add exactly ${cartItem?.bundle?.total_beans}x pocket coffee for selected plan.`,
                      );
                    }
                  }
                } else {
                  const totalQuantity = cartItem?.bundle_items?.reduce(
                    (acc: any, item: any) => acc + item?.quantity,
                    0,
                  );
                  console.log("totalQuantity", totalQuantity);
                  base_plan_validation?.map((bpv: any) => {
                    const itemQuantity = cartItem?.bundle_items
                      ?.filter(
                        (bi: any) =>
                          bi?.sub_category_id === bpv?.sub_category_id &&
                          bi?.type === selectedPlan?.type,
                      )
                      ?.reduce(
                        (acc: any, item: any) => acc + item?.quantity,
                        0,
                      );
                    console.log("bpv?.optional === 0", bpv?.optional === 0);
                    console.log("itemQuantity", itemQuantity);
                    console.log("bpv?.quantity", bpv?.quantity);
                    console.log(
                      "bpv?.quantity !== itemQuantity",
                      bpv?.quantity !== itemQuantity,
                    );
                    if (bpv?.optional === 0 && bpv?.quantity !== itemQuantity) {
                      if (
                        !errors.includes(
                          `Please add exact no. of ${bpv?.sub_category} beans for selected plan.`,
                        )
                      ) {
                        errors.push(
                          `Please add exact no. of ${bpv?.sub_category} beans for selected plan.`,
                        );
                      }
                    } else if (
                      bpv?.optional === 1 &&
                      bpv?.quantity !== itemQuantity
                    ) {
                      // if(totalQuantity === itemQuantity)
                      // if (
                      //   !errors.includes(
                      //     `Please add exact no. of ${bpv?.sub_category} beans for selected plan.`,
                      //   )
                      // ) {
                      //   errors.push(
                      //     `Please add exact no. of ${bpv?.sub_category} beans for selected plan.`,
                      //   );
                      // }
                    }
                  });
                }
                if (focType === "base_plan_foc") {
                  console.log("base_plan_foc");
                  console.log(cartItem?.bundle_items);

                  const totalGiftsQuantity = cartItem?.bundle_items.reduce(
                    (acc: any, item: { quantity: any; type: any }) =>
                      item?.type === focType
                        ? acc + (item?.quantity || 0)
                        : acc,
                    0,
                  );

                  if (totalGiftsQuantity !== freeGiftsQty) {
                    if (
                      !errors.includes(
                        `Free Gifts item must select ${freeGiftsQty} items.`,
                      )
                    ) {
                      errors.push(
                        `Free Gifts item must select ${freeGiftsQty} items.`,
                      );
                    }
                  }
                }
              } else {
                if (!errors.includes("Add Beans to continue Order.")) {
                  errors.push("Add Beans to continue Order.");
                }
              }
            }
          })?.length > 0,
      );
    } else if (selectedPlan?.type === "add_on") {
      const addon_plan_validation = selectedPlan?.bundle?.bundle_add_ons
        ?.filter(
          (bundle_add_on: any) => selectedPlan?.addon_id === bundle_add_on?.id,
        )[0]
        ?.items_detail?.filter((item_detail: any) => item_detail?.quantity > 0);
      cartArray?.map(
        ({ cartItems, orderInfo }: any) =>
          cartItems?.map((cartItem: any) => {
            if (
              cartItem?.addon_id === selectedPlan?.addon_id &&
              cartItem?.type === selectedPlan?.type
            ) {
              if (cartItem?.bundle_items?.length > 0) {
                if (plan?.pocket_coffee_boxes != null) {
                  console.log(cartItem);
                  const filteredItem = cartItem.bundle?.bundle_add_ons.find(
                    (item: { id: any }) => item.id === cartItem.addon_id,
                  );
                  const totalQuantity = cartItem?.bundle_items.reduce(
                    (acc: any, bean: { quantity: any; type: any }) =>
                      bean?.type !== focType
                        ? acc + (bean?.quantity || 0)
                        : acc,
                    0,
                  );
                  if (totalQuantity !== filteredItem.total_beans) {
                    if (
                      !errors.includes(
                        `Please add exactly ${filteredItem.total_beans}x pocket coffee for selected plan.`,
                      )
                    ) {
                      errors.push(
                        `Please add exactly ${filteredItem.total_beans}x pocket coffee for selected plan.`,
                      );
                    }
                  }
                } else {
                  addon_plan_validation?.map((bpv: any) => {
                    if (
                      bpv?.optional === 0 &&
                      bpv?.quantity !==
                        cartItem?.bundle_items
                          ?.filter(
                            (bi: any) =>
                              bi?.sub_category_id === bpv?.sub_category_id &&
                              bi?.type === selectedPlan?.type,
                          )
                          ?.reduce(
                            (acc: any, item: any) => acc + item?.quantity,
                            0,
                          )
                    ) {
                      if (
                        !errors.includes(
                          `Please add exact no. of ${bpv?.sub_category} beans for selected plan.`,
                        )
                      ) {
                        errors.push(
                          `Please add exact no. of ${bpv?.sub_category} beans for selected plan.`,
                        );
                      }
                    }
                  });
                }
                if (focType === "add_on_plan_foc") {
                  const multi_validate_foc: number[] = [];
                  foc_validation?.map((focv: any) => {
                    const cartItem_foc_quantity = cartItem?.bundle_items
                      ?.filter(
                        (bi: any) =>
                          bi?.sub_category_id === focv?.sub_category_id &&
                          bi?.type === focType,
                      )
                      ?.reduce(
                        (acc: any, item: any) => acc + item?.quantity,
                        0,
                      );
                    if (focv?.quantity !== cartItem_foc_quantity) {
                      if (focv?.optional === 1) {
                        if (
                          foc_validation?.filter(
                            (b: any) =>
                              b?.freegift_no === focv?.freegift_no &&
                              b?.optional === 1,
                          )?.length > 1
                        ) {
                          foc_validation
                            ?.filter(
                              (fv: any) =>
                                fv?.freegift_no === focv?.freegift_no,
                            )
                            ?.map((fvm: any) => {
                              if (fvm?.quantity === cartItem_foc_quantity) {
                                multi_validate_foc.push(focv?.freegift_no);
                              }
                            });
                          if (!multi_validate_foc.includes(focv?.freegift_no)) {
                            if (
                              !errors.includes(
                                `${focv?.sub_category} must select either one Addon - FOC`,
                              )
                            ) {
                              errors.push(
                                `${focv?.sub_category} must select either one Addon - FOC`,
                              );
                            }
                          }
                        } else if (cartItem_foc_quantity === 0) {
                          // console.log("optional item no need to validate");
                        } else {
                          if (
                            !errors.includes(
                              `${focv?.sub_category} Addon - FOC item must select ${focv?.quantity} items.`,
                            )
                          ) {
                            errors.push(
                              `${focv?.sub_category} Addon - FOC item must select ${focv?.quantity} items.`,
                            );
                          }
                        }
                      } else {
                        if (
                          !errors.includes(
                            `${focv?.sub_category} Addon - FOC item must select ${focv?.quantity} items.`,
                          )
                        ) {
                          errors.push(
                            `${focv?.sub_category} Addon - FOC item must select ${focv?.quantity} items.`,
                          );
                        }
                      }
                    }
                  });
                }
              } else {
                if (!errors.includes("Add Beans to continue Order.")) {
                  errors.push("Add Beans to continue Order.");
                }
              }
            }
          })?.length > 0,
      );
    } else {
      if (!errors.includes("Select Plan to continue Order.")) {
        errors.push("Select Plan to continue Order.");
      }
    }
    // if (focType === "advance_foc") {
    //   const multi_validate_foc: number[] = [];
    //   cartArray?.map(({ cartItems, orderInfo }: any) => {
    //     if (
    //       cartItems
    //         ?.map((cartItem: any) => {
    //           return cartItem?.bundle_items?.filter(
    //             (bi: any) => bi?.type === focType,
    //           )?.length;
    //         })
    //         ?.reduce((acc: any, item: any) => acc + item, 0) > 0
    //     ) {
    //       return (
    //         cartItems?.map((cartItem: any) => {
    //           if (
    //             cartItem?.id === selectedPlan?.id &&
    //             cartItem?.type === selectedPlan?.type
    //           ) {
    //             if (
    //               cartItem?.bundle_items?.filter(
    //                 (bi: any) => bi?.type === focType,
    //               )?.length > 0
    //             ) {
    //               if (focType === "advance_foc") {
    //                 foc_validation?.map((focv: any) => {
    //                   const cartItem_foc_quantity = cartItem?.bundle_items
    //                     ?.filter(
    //                       (bi: any) =>
    //                         bi?.sub_category_id === focv?.sub_category_id &&
    //                         bi?.type === focType,
    //                     )
    //                     ?.reduce(
    //                       (acc: any, item: any) => acc + item?.quantity,
    //                       0,
    //                     );
    //                   if (focv?.quantity !== cartItem_foc_quantity) {
    //                     if (focv?.optional === 1) {
    //                       if (
    //                         foc_validation?.filter(
    //                           (b: any) =>
    //                             b?.freegift_no === focv?.freegift_no &&
    //                             b?.optional === 1,
    //                         )?.length > 1
    //                       ) {
    //                         foc_validation
    //                           ?.filter(
    //                             (fv: any) =>
    //                               fv?.freegift_no === focv?.freegift_no,
    //                           )
    //                           ?.map((fvm: any) => {
    //                             if (fvm?.quantity === cartItem_foc_quantity) {
    //                               multi_validate_foc.push(focv?.freegift_no);
    //                             }
    //                           });
    //                         if (
    //                           !multi_validate_foc.includes(focv?.freegift_no)
    //                         ) {
    //                           if (
    //                             !errors.includes(
    //                               `${focv?.sub_category} must select either one Upgraded - FOC`,
    //                             )
    //                           ) {
    //                             errors.push(
    //                               `${focv?.sub_category} must select either one Upgraded - FOC`,
    //                             );
    //                           }
    //                         }
    //                       } else if (cartItem_foc_quantity === 0) {
    //                         // console.log("optional item no need to validate");
    //                       } else {
    //                         if (
    //                           !errors.includes(
    //                             `${focv?.sub_category} Upgraded - FOC item must select ${focv?.quantity} items.`,
    //                           )
    //                         ) {
    //                           errors.push(
    //                             `${focv?.sub_category} Upgraded - FOC item must select ${focv?.quantity} items.`,
    //                           );
    //                         }
    //                       }
    //                     } else {
    //                       if (
    //                         !errors.includes(
    //                           `${focv?.sub_category} Upgraded - FOC item must select ${focv?.quantity} items.`,
    //                         )
    //                       ) {
    //                         errors.push(
    //                           `${focv?.sub_category} Upgraded - FOC item must select ${focv?.quantity} items.`,
    //                         );
    //                       }
    //                     }
    //                   }
    //                 });
    //               }
    //             }
    //           }
    //         })?.length > 0
    //       );
    //     } else {
    //       if (!errors.includes("Add Upgraded - FOC to continue Order.")) {
    //         errors.push("Add Upgraded - FOC to continue Order.");
    //       }
    //     }
    //   });
    // }
    if (errors?.length === 0) {
      dispatch(setCart(cartArray));
      // console.log("navigate(/new-order-cart)");
      navigate("/new-order-cart");
    } else {
      toast.error(
        <div>
          {errors?.map((str, index) => (
            <React.Fragment key={index}>
              {str}
              {index !== errors?.length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>,
      );
    }
  };

  const footerButton = (
    <div className="flex">
      {cart?.length > 0 && (
        <Button
          className="shrink-0 h-[70px] bg-[#4A4A4A] text-white text-[20px] px-[20px] flex flex-row justify-center items-center flex-nowrap rounded-none border-[#4A4A4A]"
          //   onClick={viewCartPage}
        >
          Next
        </Button>
      )}
      <Button
        className="w-full h-[70px] bg-[#DA5E18] text-white text-[20px] flex flex-row justify-center items-center flex-nowrap rounded-none"
        onClick={() => {
          let updateCart = [];
          if (isEdit) {
            updateCart = cart?.map(
              ({ cartItems, orderInfo }: any, ciIndex: number) => {
                if (ciIndex === cartIndex) {
                  let bundle_items: any = [];
                  if (selectedBeans) {
                    bundle_items = [
                      ...bundle_items,
                      ...selectedBeans.map((selectedBean: any) => {
                        return {
                          ...selectedBean,
                          ...{
                            type: selectedPlan?.type,
                            addon_id:
                              selectedPlan?.type === "add_on" &&
                              selectedPlan?.addon_id
                                ? selectedPlan?.addon_id
                                : null,
                          },
                        };
                      }),
                    ];
                  }
                  if (selectedFreeGifts) {
                    bundle_items = [
                      ...bundle_items,
                      ...selectedFreeGifts.map((selectedFreeGift: any) => {
                        return {
                          ...selectedFreeGift,
                          ...{ type: focType },
                        };
                      }),
                    ];
                  }
                  return {
                    cartItems: [
                      ...cartItems,
                      ...[{ ...plan, ...{ bundle_items } }],
                    ],
                    orderInfo,
                  };
                }
                return { cartItems, orderInfo };
              },
            );
            validateItems(updateCart);
          } else {
            console.log("!!!!");
            const orderInfo = store.getState().order.orderInfo;
            const bundle_items = [
              ...selectedBeans.map((selectedBean: any) => {
                return {
                  ...selectedBean,
                  ...{
                    type: selectedPlan?.type,
                    addon_id:
                      selectedPlan?.type === "add_on" && selectedPlan?.addon_id
                        ? selectedPlan?.addon_id
                        : null,
                  },
                };
              }),
              ...selectedFreeGifts.map((selectedFreeGift: any) => {
                return {
                  ...selectedFreeGift,
                  ...{ type: focType },
                };
              }),
            ];
            updateCart = [
              {
                orderInfo: {
                  ...orderInfo,
                  ...{
                    sum: selectedPlan?.price * selectedPlan?.quantity,
                    sum_formatted: formatCurrency(
                      selectedPlan?.price * selectedPlan?.quantity,
                    ),
                    purchaseOption: purchaseOptions.filter(
                      (item: any) => item?.name === selectedPurchaseOption,
                    ),
                    subscriptionInfo: {
                      subscriptionId: plan?.bundle?.subscription_option_id,
                      selfCollect: checkedSelfCollect,
                    },
                    subscriptionOption: [plan?.bundle?.subscription_option],
                  },
                },
                cartItems: [
                  {
                    ...selectedPlan,
                    ...{
                      bundle_items,
                    },
                  },
                ],
              },
            ];
            console.log(updateCart);
            if (cart) {
              validateItems([...cart, ...updateCart]);
            } else {
              validateItems(updateCart);
            }
          }
        }}
      >
        Create {selectedPurchaseOption}
      </Button>
    </div>
  );

  const KnobProgress = () => {
    return (
      <img
        src={require("../../assets/images/chart-step-1.png")}
        alt="Chart step 1"
        className="w-[55px] h-[55px]"
      />
    );
  };
  const handlePurchaseOptionSelection = (name: any) => {
    navigate("/new-order", { replace: true, state: { selection: name } });
  };

  const getBeansBasedOnPlan = async (
    item: any,
    type: string,
    sub_category_id: number | null = null,
    freegift_no: number | null = null,
  ) => {
    setBeans([]);
    const subCategoryIds = sub_category_id
      ? [sub_category_id]
      : item.map((id: any) => {
          return id?.sub_category_id;
        });
    try {
      const allProducts = await get(`product/get_product_listings`);
      if (allProducts?.success && allProducts?.data) {
        const filteredProducts = allProducts.data?.filter((product: any) =>
          subCategoryIds.includes(product?.sub_category?.id),
        );
        let updatedItems = [];
        if (type === "products") {
          if (selectedPlan?.pocket_coffee_boxes != null) {
            if (Array.isArray(filteredProducts[0].products)) {
              filteredProducts[0].products =
                filteredProducts[0].products.filter(
                  (product: any) => product.subscription === 1,
                );
            }
          }
          updatedItems = filteredProducts?.map(
            ({ sub_category, products }: any) => {
              return {
                sub_category,
                products: products.map((product: any) => {
                  const selected_qty = selectedBeans.filter(
                    (selectedBean: any) => selectedBean?.id === product?.id,
                  );
                  return {
                    ...product,
                    ...{
                      selected_quantity:
                        selected_qty?.length > 0
                          ? selected_qty[0]?.quantity
                          : 0,
                    },
                  };
                }),
              };
            },
          );
        } else if (type === "focs") {
          updatedItems = filteredProducts.map(
            ({ sub_category, products }: any) => {
              return {
                sub_category,
                products: products.map((product: any) => {
                  const selected_qty = selectedFreeGifts.filter(
                    (selectedFreeGift: any) =>
                      selectedFreeGift?.id === product?.id,
                  );
                  return {
                    ...product,
                    ...{
                      selected_quantity:
                        selected_qty?.length > 0
                          ? selected_qty[0]?.quantity
                          : 0,
                      freegift_no: freegift_no ?? 0,
                    },
                  };
                }),
              };
            },
          );
        }
        setBeans(updatedItems);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleBeansQuantity = (event: any) => {
    const { name, quantity, sub_category } = event;
    if (dialogType === "beans") {
      setSelectedBeans((prevSelectedBeans) => {
        const updatedSelectedBeans = [...prevSelectedBeans];
        const matchingBeanIndex = updatedSelectedBeans.findIndex(
          (bean) =>
            bean?.name === name &&
            bean?.sub_category?.name === sub_category?.name,
        );
        if (matchingBeanIndex !== -1) {
          if (quantity > 0) {
            updatedSelectedBeans[matchingBeanIndex].quantity = quantity;
          } else {
            updatedSelectedBeans.splice(matchingBeanIndex, 1);
          }
        } else {
          if (quantity > 0) {
            updatedSelectedBeans.push(event);
          }
        }
        return updatedSelectedBeans;
      });
    } else {
      setSelectedFreeGifts((prevSelectedBeans) => {
        const updatedSelectedBeans = [...prevSelectedBeans];
        const matchingBeanIndex = updatedSelectedBeans.findIndex(
          (bean) =>
            bean?.name === name &&
            bean?.sub_category?.name === sub_category?.name,
        );

        if (matchingBeanIndex !== -1) {
          if (quantity > 0) {
            updatedSelectedBeans[matchingBeanIndex].quantity = quantity;
          } else {
            updatedSelectedBeans.splice(matchingBeanIndex, 1);
          }
        } else {
          if (quantity > 0) {
            updatedSelectedBeans.push(event);
          }
        }
        return updatedSelectedBeans;
      });
    }
  };

  const handleGiftsQuantity = (event: any) => {
    console.log("handleGiftsQuantity");
    console.log(event);
    const { id, quantity, available_stocks } = event;

    setSelectedFreeGifts((prevSelectedGifts) => {
      const updatedSelectedGifts = [...prevSelectedGifts];
      const matchingGiftIndex = updatedSelectedGifts.findIndex(
        (item) => item?.id === id,
      );

      if (matchingGiftIndex !== -1) {
        if (quantity > 0) {
          updatedSelectedGifts[matchingGiftIndex].quantity = quantity;
        } else {
          updatedSelectedGifts.splice(matchingGiftIndex, 1);
        }
      } else {
        if (quantity > 0) {
          updatedSelectedGifts.push(event);
        }
      }
      console.log("updatedSelectedGifts");
      console.log(updatedSelectedGifts);
      return updatedSelectedGifts;
    });
  };

  const handleSelfCollectCheck = (event: any) => {
    setCheckedSelfCollect(event.checked);
  };

  const ProductCheckBox = ({ cId, cName, cData }: any) => {
    return (
      <div className="flex align-items-center my-3">
        <Checkbox
          inputId={cId}
          name={cName}
          value="1"
          onChange={({ checked }: any) => {
            if (checked) {
              plan?.bundle?.bundle_focs
                ?.filter(
                  (bundle_foc: any) =>
                    bundle_foc?.foc_type === focType &&
                    bundle_foc?.items_detail?.length > 0 &&
                    bundle_foc?.items_detail.filter(
                      (item_detail: any) =>
                        item_detail?.product_id !== 0 &&
                        item_detail?.product_info,
                    )?.length > 0,
                )
                ?.map((foc_item_detail: any) => {
                  const select_bundle_foc_product =
                    foc_item_detail?.items_detail.filter(
                      (foc_product: any) =>
                        foc_product?.product_id === cData?.product_info?.id &&
                        foc_product?.product_info,
                    );
                  if (select_bundle_foc_product?.length > 0) {
                    setSelectedFreeGifts([
                      ...selectedFreeGifts,
                      {
                        ...select_bundle_foc_product[0]?.product_info,
                        ...{
                          quantity: select_bundle_foc_product[0]?.quantity,
                          type: focType,
                          freegift_no: cData?.freegift_no,
                        },
                      },
                    ]);
                  }
                  return select_bundle_foc_product;
                });
            } else {
              setSelectedFreeGifts(
                selectedFreeGifts?.filter(
                  (foc: any) => foc?.id !== cData?.product_info?.id,
                ),
              );
            }
          }}
          checked={
            selectedFreeGifts?.filter(
              (gift: any) => gift?.id === cData?.product_info?.id,
            )?.length > 0
          }
        />
        <label
          htmlFor={cId}
          className="ml-2 text-sm line-clamp-3 text-[#3E3E3E]"
        >
          {cData?.product_info?.name} ({cData?.product_info?.sub_category?.name}
          ) x {cData?.quantity} {cData?.optional === 1 ? "(Optional)" : ""}
        </label>
      </div>
    );
  };

  // const CategoryCheckBox = ({ cId, cName, cData }: any) => {
  //   return (
  //     <div className="flex align-items-center my-3">
  //       <Checkbox
  //         inputId={cId}
  //         name={cName}
  //         value="1"
  //         onChange={({ checked }: any) => {
  //           if (checked) {
  //             if (
  //               plan?.bundle?.bundle_focs?.filter(
  //                 (bundle_foc: any) =>
  //                   bundle_foc?.foc_type === focType &&
  //                   bundle_foc?.items_detail?.length > 0 &&
  //                   bundle_foc?.items_detail.filter(
  //                     (item_detail: any) => item_detail?.product_id === 0,
  //                   )?.length > 0,
  //               )?.length > 0
  //             ) {
  //               const freeGiftBeans = plan?.bundle?.bundle_focs
  //                 ?.filter((foc: any) => foc?.foc_type === focType)
  //                 .map((foc: any) => foc?.items_detail)
  //                 .flat()
  //                 .filter((item: any) => item?.quantity > 0);

  //               getBeansBasedOnPlan(
  //                 freeGiftBeans,
  //                 "focs",
  //                 cData?.sub_category_id,
  //                 cData?.freegift_no,
  //               );
  //               setDialogType("free_gift");
  //               setSelectBeansVisible(true);
  //             }
  //           } else {
  //             setSelectedFreeGifts(
  //               selectedFreeGifts?.filter(
  //                 (foc: any) => foc?.sub_category_id !== cData?.sub_category_id,
  //               ),
  //             );
  //           }
  //         }}
  //         checked={
  //           selectedFreeGifts?.filter(
  //             (gift: any) => gift?.sub_category?.name === cData?.sub_category,
  //           )?.length > 0
  //         }
  //       />
  //       <label
  //         htmlFor={cId}
  //         className="ml-2 text-sm line-clamp-3 text-[#3E3E3E]"
  //       >
  //         {cData?.sub_category} x {cData?.quantity}{" "}
  //         {cData?.optional === 1 ? "(Optional)" : ""}
  //       </label>
  //     </div>
  //   );
  // };

  // const ProductRadioButton = ({ cId, cName, cData }: any) => {
  //   return (
  //     <div className="flex align-items-center my-3">
  //       <RadioButton
  //         inputId={cId}
  //         name={cName}
  //         value="1"
  //         onChange={({ checked }: any) => {
  //           if (checked) {
  //             plan?.bundle?.bundle_focs
  //               ?.filter(
  //                 (bundle_foc: any) =>
  //                   bundle_foc?.foc_type === focType &&
  //                   bundle_foc?.items_detail?.length > 0 &&
  //                   bundle_foc?.items_detail.filter(
  //                     (item_detail: any) =>
  //                       item_detail?.product_id !== 0 &&
  //                       item_detail?.product_info,
  //                   )?.length > 0,
  //               )
  //               ?.map((foc_item_detail: any) => {
  //                 const select_bundle_foc_product =
  //                   foc_item_detail?.items_detail.filter(
  //                     (foc_product: any) =>
  //                       foc_product?.optional === 1 &&
  //                       foc_product?.product_id === cData?.product_info?.id &&
  //                       foc_product?.product_info,
  //                   );
  //                 const unselect_bundle_foc_product =
  //                   foc_item_detail?.items_detail.filter(
  //                     (foc_product: any) =>
  //                       foc_product?.optional === 1 &&
  //                       (foc_product?.product_id !== cData?.product_info?.id ||
  //                         !foc_product?.product_id) &&
  //                       foc_product?.freegift_no === cData?.freegift_no,
  //                   );
  //                 const usg = unselect_bundle_foc_product?.map(
  //                   (foc: any) => foc?.sub_category_id,
  //                   // (foc: any) => foc?.product_id,
  //                 );
  //                 if (select_bundle_foc_product?.length > 0) {
  //                   setSelectedFreeGifts([
  //                     ...(selectedFreeGifts?.filter(
  //                       (sfg: any) => !usg.includes(sfg?.sub_category_id),
  //                     ) || selectedFreeGifts),
  //                     {
  //                       ...select_bundle_foc_product[0]?.product_info,
  //                       ...{
  //                         quantity: select_bundle_foc_product[0]?.quantity,
  //                         type: focType,
  //                         freegift_no: cData?.freegift_no,
  //                       },
  //                     },
  //                   ]);
  //                 }
  //                 return select_bundle_foc_product;
  //               });
  //           } else {
  //             setSelectedFreeGifts(
  //               selectedFreeGifts?.filter(
  //                 (foc: any) => foc?.id !== cData?.product_info?.id,
  //               ),
  //             );
  //           }
  //         }}
  //         checked={
  //           selectedFreeGifts?.filter(
  //             (gift: any) => gift?.id === cData?.product_info?.id,
  //           )?.length > 0
  //         }
  //       />
  //       <label
  //         htmlFor={cId}
  //         className="ml-2 text-sm line-clamp-3 text-[#3E3E3E]"
  //       >
  //         {cData?.product_info?.name} ({cData?.product_info?.sub_category?.name}
  //         ) x {cData?.quantity} {cData?.optional === 1 ? "(Optional)" : ""}
  //       </label>
  //     </div>
  //   );
  // };

  // const CategoryRadioButton = ({ cId, cName, cData }: any) => {
  //   return (
  //     <div className="flex align-items-center my-3">
  //       <RadioButton
  //         inputId={cId}
  //         name={cName}
  //         value="1"
  //         onChange={({ checked }: any) => {
  //           if (checked) {
  //             if (
  //               plan?.bundle?.bundle_focs?.filter(
  //                 (bundle_foc: any) =>
  //                   bundle_foc?.foc_type === focType &&
  //                   bundle_foc?.items_detail?.length > 0 &&
  //                   bundle_foc?.items_detail.filter(
  //                     (item_detail: any) => item_detail?.product_id === 0,
  //                   )?.length > 0,
  //               )?.length > 0
  //             ) {
  //               setSelectedFreeGifts(
  //                 selectedFreeGifts?.filter(
  //                   (foc: any) => foc?.freegift_no !== cData?.freegift_no,
  //                 ),
  //               );
  //               const freeGiftBeans = plan?.bundle?.bundle_focs
  //                 ?.filter((foc: any) => foc?.foc_type === focType)
  //                 .map((foc: any) => foc?.items_detail)
  //                 .flat()
  //                 .filter((item: any) => item?.quantity > 0);

  //               getBeansBasedOnPlan(
  //                 freeGiftBeans,
  //                 "focs",
  //                 cData?.sub_category_id,
  //                 cData?.freegift_no,
  //               );
  //               setDialogType("free_gift");
  //               setSelectBeansVisible(true);
  //             }
  //           } else {
  //             setSelectedFreeGifts(
  //               selectedFreeGifts?.filter(
  //                 (foc: any) => foc?.sub_category_id !== cData?.sub_category_id,
  //               ),
  //             );
  //           }
  //         }}
  //         checked={
  //           selectedFreeGifts?.filter(
  //             (gift: any) => gift?.sub_category?.name === cData?.sub_category,
  //           )?.length > 0
  //         }
  //       />
  //       <label
  //         htmlFor={cId}
  //         className="ml-2 text-sm line-clamp-3 text-[#3E3E3E]"
  //       >
  //         {cData?.sub_category} x {cData?.quantity}{" "}
  //         {cData?.optional === 1 ? "(Optional)" : ""}
  //       </label>
  //     </div>
  //   );
  // };

  // const SelectedFOCBeans = ({ beanData }: any) => {
  //   return (
  //     <>
  //       {selectedFreeGifts.length > 0 &&
  //         selectedFreeGifts
  //           ?.filter(
  //             (gift: any) =>
  //               gift?.sub_category?.name === beanData?.sub_category,
  //           )
  //           ?.map((gift: any, index: any) => (
  //             <div key={index}>
  //               <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
  //                 {gift?.name}
  //               </p>
  //             </div>
  //           ))}
  //     </>
  //   );
  // };

  const handleButtonClick = () => {
    setSelectedPlan({
      ...plan,
      type: "product",
    });
    setFocType("base_plan_foc");
    setSelectedBeans([]);
    setSelectedFreeGifts([]);
    // if (samePlan) {
    //   setFocType("advance_foc");
    // }
    // if (plan?.pocket_coffee_boxes != null) {
    //   // Update pocketCoffee quantity
    //   const updatedPocketCoffee = {
    //     ...pocketCoffee,
    //     pocket_coffee_boxes: plan?.pocket_coffee_boxes,
    //     quantity: plan?.pocket_coffee_boxes,
    //   };
    //   setSelectedBeans([updatedPocketCoffee]);
    // }
  };

  const getSelectedGiftQty = (id: any) => {
    const index = selectedFreeGifts.findIndex((item: any) => item.id === id);
    if (index !== -1) {
      const selectedGift = selectedFreeGifts[index];
      console.log(selectedGift);
      return selectedGift.quantity;
    } else {
      return 0;
    }
  };

  return (
    <Layout
      headerType={HeaderType.back}
      pageTitle={"New Order"}
      headerButton={headerButton}
      footerButton={footerButton}
      stickyHeaderFooter={true}
      rightSideIcon={<KnobProgress />}
    >
      <PurchaseOptionsDialog
        selected={selectedPurchaseOption}
        visible={purchaseOptionVisible}
        onHide={() => {
          setPurchaseOptionVisible(false);
        }}
        onSelect={handlePurchaseOptionSelection}
      />
      <div>
        <div className="flex items-center mb-[10px]">
          <Checkbox
            name="self_collected"
            onChange={handleSelfCollectCheck}
            checked={checkedSelfCollect}
          />
          <label className="text-[#FFFFFF] capitalize text-[14px] pl-1.5">
            Self Collect For First Order
          </label>
        </div>
        <div className="p-3 bg-[#343426] rounded-[7px] mb-[10px]">
          <label className="text-[#DA5E18] text-base capitalize">
            {plan?.name} {"(" + plan?.bundle?.subscription_option?.name + ")"}
          </label>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
          <div className="col-span-1">
            <div className="rounded-lg shadow-md border-solid border-[0.7px] border-[#E3E3E3] h-[345px] px-2 py-2.5 bg-[#F7F7F7]">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
                    Base Plan
                  </p>
                  <p className="text-base font-bold text-[#DA5E18]">
                    {plan?.price_formatted}
                  </p>
                  {plan?.bundle?.items_detail?.length > 0 &&
                    plan?.bundle?.items_detail.map(
                      (item: any, index: number) => (
                        <div key={index}>
                          {item?.quantity > 0 && (
                            <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
                              {item?.sub_category} {`x ${item?.quantity}`}{" "}
                              {item?.optional ? "(Optional)" : ""}
                            </p>
                          )}
                        </div>
                      ),
                    )}
                  {plan?.pocket_coffee_boxes != null && (
                    <div>
                      <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
                        Quantity x {plan?.bundle.total_beans}
                      </p>
                    </div>
                  )}
                </div>
                <div>
                  <Button
                    className="justify-center w-full"
                    onClick={handleButtonClick}
                    disabled={isEdit}
                  >
                    {selectedPlan?.type === "product" ? "Selected" : "Select"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* {!isEdit &&
            plan?.bundle?.bundle_add_ons?.length > 0 &&
            plan?.bundle?.bundle_add_ons.map((row: any, index: number) => (
              <div
                className="col-span-1"
                key={`new-order-table-grid-row-${index}`}
              >
                <div className="rounded-lg shadow-md border-solid border-[0.7px] border-[#E3E3E3] h-[345px] px-2 py-2.5 bg-[#F7F7F7]">
                  <div className="flex flex-col justify-between h-full">
                    <div className="">
                      <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
                        {row?.add_on_name}
                      </p>
                      <p className="text-base font-bold text-[#DA5E18]">
                        {row?.price_formatted} (+{row?.price_diff_formatted})
                      </p>
                      {plan?.pocket_coffee_boxes != null && (
                        <div>
                          <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
                            Quantity x {row?.total_beans}
                          </p>
                        </div>
                      )}
                      {row?.items_detail?.length > 0 &&
                        row?.items_detail.map(
                          (item: any, index: number) =>
                            item?.quantity > 0 && (
                              <div className="" key={index}>
                                <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
                                  {item?.sub_category} {`x ${item?.quantity}`}{" "}
                                  {item?.optional ? "(Optional)" : ""}
                                </p>
                              </div>
                            ),
                        )}
                    </div>
                    <div>
                      <Button
                        className="justify-center w-full"
                        onClick={() => {
                          setSelectedPlan({
                            ...plan,
                            ...{
                              price: row?.price,
                              price_formatted: formatCurrency(row?.price),
                              quantity: row?.quantity,
                              addon_id: row?.id,
                              add_on_name: row?.add_on_name,
                              type: "add_on",
                            },
                          });
                          setFocType("add_on_plan_foc");
                          setSelectedBeans([]);
                          setSelectedFreeGifts([]);
                          // if (samePlan) {
                          //   setFocType("advance_foc");
                          // }
                        }}
                        disabled={isEdit}
                      >
                        {row?.id === selectedPlan?.addon_id
                          ? "Selected"
                          : "Select"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
        </div>
      </div>
      {selectedPlan ? (
        <div>
          <div className="flex items-center justify-between bg-[#343426] rounded-[7px] mt-[20px] mb-[10px] p-3">
            <label className="text-[#DA5E18] text-[16px]">
              {selectedPlan?.add_on_name
                ? selectedPlan?.add_on_name
                : "Base Plan"}
            </label>
            {!isEdit && (
              <Button
                link
                className={`p-0`}
                onClick={() => {
                  setSelectedPlan(null);
                  setSelectedBeans([]);
                  setSelectedFreeGifts([]);
                }}
              >
                <img
                  src={require("../../assets/images/order/icon-delete-circle.png")}
                  alt="Delete Icon"
                  className={`w-[24px] h-[24px]`}
                />
              </Button>
            )}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
            {selectedPlan?.pocket_coffee_boxes === null && (
              <div className="col-span-1">
                <div className="rounded-lg shadow-md border-solid border-[0.7px] border-[#E3E3E3] h-[345px] px-2 py-2.5 bg-[#F7F7F7]">
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <p className="text-center">Beans</p>
                      {/* <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
            Total Beans: {selectedPlan?.total_beans}
          </p> */}
                      {selectedBeans.length > 0 &&
                        selectedBeans.map((bean, index) => (
                          <div key={index}>
                            <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
                              {bean?.name} ({bean?.sub_category?.name}) x{" "}
                              {bean?.quantity}
                            </p>
                          </div>
                        ))}
                    </div>
                    <Button
                      className="justify-center"
                      onClick={() => {
                        const filteredItems =
                          selectedPlan?.bundle?.items_detail?.filter(
                            (item: any) => item?.quantity > 0,
                          );
                        console.log(filteredItems);
                        getBeansBasedOnPlan(filteredItems, "products");
                        setDialogType("beans");
                        setSelectBeansVisible(true);
                      }}
                    >
                      {selectedBeans.length > 0 ? "Edit Beans" : "Choose Beans"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {selectedPlan?.pocket_coffee_boxes != null && (
              <div className="col-span-1">
                <div className="rounded-lg shadow-md border-solid border-[0.7px] border-[#E3E3E3] h-[345px] px-2 py-2.5 bg-[#F7F7F7]">
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <p className="text-center">Pocket Coffee</p>
                      {/* <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
            Total Beans: {selectedPlan?.total_beans}
          </p> */}
                      {selectedBeans.length > 0 &&
                        selectedBeans.map((bean, index) => (
                          <div key={index}>
                            <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
                              {bean?.name} ({bean?.sub_category?.name}) x{" "}
                              {bean?.quantity}
                            </p>
                          </div>
                        ))}
                    </div>
                    <Button
                      className="justify-center"
                      onClick={() => {
                        const filteredItems = pocketCoffees;
                        console.log(filteredItems);
                        getBeansBasedOnPlan(filteredItems, "products");
                        setDialogType("beans");
                        setSelectBeansVisible(true);
                      }}
                    >
                      {selectedBeans.length > 0
                        ? "Edit Pocket Coffee"
                        : "Choose Pocket Coffee"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {/* {selectedPlan?.pocket_coffee_boxes != null && (
              <div className="col-span-1">
                <div className="rounded-lg shadow-md border-solid border-[0.7px] border-[#E3E3E3] h-[345px] px-2 py-2.5 bg-[#F7F7F7]">
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <p className="text-center">Pocket Coffee</p>
                      {selectedBeans.length > 0 &&
                        selectedBeans.map((bean, index) => (
                          <div key={index}>
                            <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
                              {bean?.name} x {bean?.quantity}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )} */}

            {freeGifts.length !== 0 && (
              <div className="col-span-1">
                <div className="rounded-lg shadow-md border-solid border-[0.7px] border-[#E3E3E3] h-[345px] px-2 py-2.5 bg-[#F7F7F7]">
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <p className="text-center">Free Gift</p>
                      <div
                        // key={`foc-row-${itemIndex}`}
                        className="overflow-y-auto h-[200px]"
                      >
                        {selectedFreeGifts.length > 0 &&
                          selectedFreeGifts.map((item, index) => (
                            <div key={index}>
                              <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
                                {item?.name} x {item?.quantity}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                    <Button
                      className="justify-center"
                      onClick={() => {
                        setSelectGiftsVisible(true);
                      }}
                    >
                      {selectedFreeGifts.length > 0
                        ? "Edit Gifts"
                        : "Choose Gifts"}
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {/* {((plan?.bundle?.bundle_focs?.length > 0 &&
              plan?.bundle?.bundle_focs.filter(
                (foc: any) => foc?.foc_type === focType,
              )?.length > 0 &&
              selectedFreeGifts.length === 0) ||
              selectedFreeGifts.length > 0) && (
              <div className="col-span-1">
                <div className="rounded-lg shadow-md border-solid border-[0.7px] border-[#E3E3E3] h-[345px] px-2 py-2.5 bg-[#F7F7F7]">
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <p className="text-center">Free Gift</p>
                      {plan?.bundle?.bundle_focs?.length > 0 &&
                        plan?.bundle?.bundle_focs
                          ?.filter(
                            (bundle_foc: any) =>
                              bundle_foc?.foc_type === focType &&
                              (["base_plan_foc", "advance_foc"].includes(
                                focType,
                              ) ||
                                (focType === "add_on_plan_foc" &&
                                  bundle_foc?.add_on_id ===
                                    selectedPlan?.addon_id)),
                          )
                          ?.map((foc: any, focIndex: any) => (
                            <div
                              key={`foc-row-${focIndex}`}
                              className="overflow-y-auto h-[290px]"
                            >
                              {foc?.items_detail?.map(
                                (item: any, itemIndex: any) => (
                                  <React.Fragment key={`foc-item-${itemIndex}`}>
                                    <div>Free Gift #{item.freegift_no}</div>
                                    {plan?.bundle?.bundle_focs
                                      ?.filter(
                                        (o: any) => o?.foc_type === focType,
                                      )[0]
                                      ?.items_detail?.filter(
                                        (b: any) =>
                                          b?.freegift_no ===
                                            item?.freegift_no &&
                                          b?.optional === 1,
                                      )?.length > 1 && (
                                      <>
                                        {item?.product_info && (
                                          <ProductRadioButton
                                            cId={`foc-item-${itemIndex}`}
                                            cName={`foc-item-name-${item?.freegift_no}`}
                                            cData={item}
                                          />
                                        )}
                                        {!item?.product_info && (
                                          <>
                                            <CategoryRadioButton
                                              cId={`foc-item-${itemIndex}`}
                                              cName={`foc-item-name-${item?.freegift_no}`}
                                              cData={item}
                                            />
                                            <SelectedFOCBeans beanData={item} />
                                          </>
                                        )}
                                        <hr />
                                      </>
                                    )}
                                    {!(
                                      plan?.bundle?.bundle_focs
                                        ?.filter(
                                          (o: any) => o?.foc_type === focType,
                                        )[0]
                                        ?.items_detail?.filter(
                                          (b: any) =>
                                            b?.freegift_no ===
                                              item?.freegift_no &&
                                            b?.optional === 1,
                                        )?.length > 1
                                    ) && (
                                      <>
                                        {item?.product_info && (
                                          <ProductCheckBox
                                            cId={`foc-item-${itemIndex}`}
                                            cName={`foc-item-name-${item?.freegift_no}`}
                                            cData={item}
                                          />
                                        )}
                                        {!item?.product_info && (
                                          <>
                                            <CategoryCheckBox
                                              cId={`foc-item-${itemIndex}`}
                                              cName={`foc-item-name-${item?.freegift_no}`}
                                              cData={item}
                                            />
                                            <SelectedFOCBeans beanData={item} />
                                          </>
                                        )}
                                        <hr />
                                      </>
                                    )}
                                  </React.Fragment>
                                ),
                              )}
                            </div>
                          ))}
                    </div>
                    <div className="w-full">
                      {samePlan && focType === "advance_foc" && (
                        <p className="text-sm">
                          You have 1 or more of the same plan(s) in your cart,
                          you are now eligible for upgraded free gift.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      ) : (
        ""
      )}

      <Dialog
        header={
          plan?.pocket_coffee_boxes != null
            ? "Select Pocket Coffee"
            : "Select Beans"
        }
        footer={
          <AcceptButton
            label="Save"
            onClick={() => setSelectBeansVisible(false)}
            className="mt-[15px] !m-auto"
          />
        }
        visible={selectBeansVisible}
        style={{
          width: "70%",
          height: "70%",
          border: 0,
          overflow: "hidden",
        }}
        onHide={() => {
          setSelectBeansVisible(false);
          // setSelectedBeans([]);
        }}
        modal
      >
        <div className="modal-content">
          {beans?.length > 0 &&
            beans.map((row: InventoryGroup, index: number) => {
              return (
                <div key={`select-beans-${index}`}>
                  <div className="py-3 bg-[#343426] rounded-[7px] pl-3 mt-[20px] mb-[10px]">
                    <label className="text-[#DA5E18] text-base capitalize">
                      {row.sub_category?.name}
                    </label>
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
                    {row.products?.length > 0 &&
                      row.products.map((row: Inventory) => (
                        <div
                          className="col-span-1"
                          key={`new-order-table-grid-row-${row.id}`}
                        >
                          <GridView
                            item={row}
                            onQuantityChange={(event: any) =>
                              handleBeansQuantity(event)
                            }
                          />
                        </div>
                      ))}
                  </div>
                </div>
              );
            })}
        </div>
      </Dialog>

      <Dialog
        header={"Select Gift"}
        footer={
          <AcceptButton
            label="Save"
            onClick={() => setSelectGiftsVisible(false)}
            className="mt-[15px] !m-auto"
          />
        }
        visible={selectGiftsVisible}
        style={{
          width: "70%",
          height: "70%",
          border: 0,
          overflow: "hidden",
        }}
        onHide={() => {
          setSelectGiftsVisible(false);
          // setSelectedBeans([]);
        }}
        modal
      >
        <div className="modal-content">
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
            {freeGifts
              .filter((item: any) => item.available_stocks > 0)
              .map((item: any, itemIndex: any) => (
                <div
                  className="col-span-1"
                  key={`new-order-table-grid-row-${item.id}`}
                >
                  <FreeGiftGrid
                    item={item}
                    selectedQty={getSelectedGiftQty(item.id)}
                    onQuantityChange={(event: any) =>
                      handleGiftsQuantity(event)
                    }
                  />
                </div>
              ))}
          </div>
        </div>
      </Dialog>
    </Layout>
  );
};

export default PlanAddons;
