import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";

interface BeanInputOptionalProps {
  inputId: string;
  inputName: string;
  label: string;
  checkBoxValue: string;
  inputValue: number;
  beansOptional: boolean;
  onBeansOptionalChange: (event: CheckboxChangeEvent) => void;
  handleInputOnChange: (value: number | null) => void;
}

const BeanInputOptional: React.FC<BeanInputOptionalProps> = ({
  inputId,
  inputName,
  label,
  checkBoxValue,
  inputValue,
  beansOptional,
  onBeansOptionalChange,
  handleInputOnChange,
}) => {
  return (
    <div>
      <div className="text-[#292929] capitalize mb-2 flex gap-2">
        {label}
        <div>
          <Checkbox
            inputId={inputId}
            name={inputName}
            value={checkBoxValue}
            onChange={onBeansOptionalChange}
            checked={beansOptional}
            disabled={true}
          />
          <label
            htmlFor={inputId}
            className="pl-1 text-xs select-none cursor-pointer"
          >
            Optional
          </label>
        </div>
      </div>

      <InputNumber
        value={inputValue}
        onChange={(e) => handleInputOnChange(e.value)}
        className="w-full"
      />
    </div>
  );
};

export default BeanInputOptional;
